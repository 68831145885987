import React from 'react';
import { Row, Col, Button } from 'antd';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from 'styled-components';
import BasicLayout from 'layout/Basic';

const Container = styled.div`
    width: 100%;
    min-height: 40vh;
`;

const Title = styled.h1`
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.color || props.theme.colors.greyishBrown};
`;

const NotFoundPage = (): JSX.Element => {
    const router = useRouter();
    return (
        <BasicLayout ads={{ skyscraper: [], billboard: [], wideboardTop: [] }}>
            <Row>
                <Col span={24}>
                    <Title>Przepraszamy, ale strona nie istneje.</Title>
                </Col>
                <Col span={24}>
                    <Container>
                        <Image alt="not found image" src="/static/images/404.svg" fill />
                    </Container>
                </Col>
                <Col span={24}>
                    <Row justify="center">
                        <Col>
                            <Button type="primary" onClick={() => router.back()}>
                                Wróć do poprzedniej strony
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BasicLayout>
    );
};

export default NotFoundPage;
